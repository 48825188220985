<template>
  <div class="network">
    <div class="nav-wrap">
      <navigation-bar
        class="topfix"
        ref="topInput"
        @search="handleSearch"
        :placeholder="searchtip"
        v-show="!showTopCheckBar"
      >
    
      </navigation-bar>
    </div>
    
    <div
      :class="['netfile-tbar', showTopFileBack ? 'netfile-tbar-active' : '']"
      v-if="showTopFileBack"
    >
      <van-nav-bar fixed placeholder  :title="parentDom.filename" left-arrow  :right-text="isAll ? '取消全选' : '全选'" z-index="100"  left-text="返回" @click-left="onTopFileBack" @click-right="allChoice"/>
    </div>

    <div
      :class="['filechecktop', showTopCheckBar ? 'filechecktop-active' : '']"
      v-show="showTopCheckBar"
    >
      <van-nav-bar
        :right-text="isAll ? '取消全选' : '全选'"
        left-text="取消"
        :title="opres.length ? `已选中${opres.length}个文件` : ''"
        @click-left="onCheckCancel"
        @click-right="onAllChecks"
      />
    </div>

    <div
      class="network-content" :class="tabShow==false?'network-content-active':''"
    >
      <van-tabs @change="onTabChange"  v-model="tabType" swipeable>

        <template v-if="tabShow">
          <van-tab v-for="(item, tabIndex) in filetype" :title="item.name" :key="tabIndex"  >
          </van-tab>
        </template>

        <van-pull-refresh
            v-model="isRefresh"
            success-text="刷新成功"
            @refresh="onRefresh"
            :disabled="showMoreChoice || showTopFileBack"
          >
            <van-list
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              :offset="100"
              finished-text="我也是有底线的~"
              @load="onLoad"
            >
              <div
                class="network-panel"
                :style="{
                  height: topFolder.length <= 6 ? 'calc(100vh - 94px)' : '',
                }"
              >
                <div class="torefresh" v-if="torefresh">
                  网络走丢了,下拉刷新~
                </div>
                <div class="unfilehere" v-if="unfile">这里啥也没有~</div>
                <net-file-item
                  ref="netfile"
                  v-for="(item, index) in topFolder"
                  :file="item"
                  :key="index"
                  @openFolder="openFolder"
                  @opFile="opFile"
                  @mulchoice="mulchoice"
                />
              </div>
            </van-list>
          </van-pull-refresh>
      </van-tabs>
    </div>
    <van-popup round v-model="showNotice">
      <div
        :class="[
          'over',
          setting == '删除' ||
          setting == '批量删除' ||
          setting == '彻底删除' ||
          setting == '批量彻底删除' ||
          setting == '恢复' ||
          setting == '批量恢复' ||
          setting == '分享' ||
          setting == '重命名' ||
          setting == '批量下载'
            ? 'over-del'
            : '',
        ]"
      >
        <div class="content">
          <div class="tit">
            <span
              >{{ setting
              }}<span
                v-if="
                  setting == '删除' ||
                  setting == '批量删除' ||
                  setting == '彻底删除' ||
                  setting == '批量彻底删除'
                "
                >文件</span
              >
            </span>
          </div>
          <div class="main">
            <input
              type="text"
              v-model="rename"
              :placeholder="rename"
              v-show="setting == '重命名'"
            />
            <p
              class="recovery"
              v-show="setting == '恢复' || setting == '批量恢复'"
            >
              确认恢复吗?
            </p>
            <p
              class="deltxt"
              v-show="setting == '删除' || setting == '批量删除'"
            >
              30天内可在回收站中找回已删文件
            </p>
            <p
              class="delforever"
              v-if="setting == '彻底删除' || setting == '批量彻底删除'"
            >
              是否彻底删除文件？
            </p>
            <p class="sharetxt" v-show="setting == '分享'">确定分享文件？</p>
            <p class="downtxt" v-show="setting == '批量下载'">确定下载吗？</p>
          </div>
        </div>
        <div class="btns">
          <span class="cancel" @click="cancel">取消</span>
            <span
            class="sure"
            @click="sure"
            v-if="
              setting == '删除' ||
              setting == '批量删除' ||
              setting == '彻底删除' ||
              setting == '批量彻底删除'
            "
            >确认删除</span
          >
          <span
            class="sure"
            @click="sure"
            v-if="
              setting != '删除' &&
              setting != '批量删除' &&
              setting != '彻底删除' &&
              setting != '批量彻底删除'
            "
            >确定</span
          >
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showOperate" round position="bottom">
      <div class="oplist">
        <div
          class="oplist-item"
          v-if="tabType != filetype.length - 1"
          @click="shareFile"
        >
          <img src="~@image/net-op-share.png" />
          <span class="txt">分享</span>
        </div>
        <div
          class="oplist-item"
          v-if="
            tabType != filetype.length - 1 &&
            opres.length &&
            opres[0].isMarked == 1
          "
        >
          <!-- @click="cancelCollect" -->
          <img src="~@image/net-op-sc.png" />
          <span class="txt">取消收藏</span>
        </div>
        <div
          class="oplist-item"
          v-if="tabType != filetype.length - 1"
          @click="moveFile"
        >
          <img src="~@image/net-op-move.png" />
          <span class="txt">移动</span>
        </div>
        <div
          class="oplist-item"
          v-if="tabType != filetype.length - 1"
          @click="renameFile"
        >
          <img src="~@image/net-op-edit.png" />
          <span class="txt">重命名</span>
        </div>
        <div
          class="oplist-item"
          v-if="tabType == filetype.length - 1"
          @click="recovery"
        >
          <img src="~@image/net-op-edit.png" />
          <span class="txt">恢复</span>
        </div>

        <div
          class="oplist-item"
          v-if="tabType != filetype.length - 1"
          @click="delFile"
        >
          <img src="~@image/net-op-del.png" />
          <span class="txt">删除</span>
        </div>
        <div
          class="oplist-item"
          v-if="tabType == filetype.length - 1"
          @click="physicallyDelFile"
        >
          <img src="~@image/net-op-del.png" />
          <span class="txt">彻底删除</span>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showMoving" round position="bottom">
      <div class="move">
        <div class="top">
          <span
            class="curfile"
            v-if="moveFolder.parentid && moveFolder.parentid != 0"
            @click="backPrevFolder"
          >
            <van-icon name="arrow-left" />
          </span>
          移动文件
          <span class="move-close" @click="hideMoveFile"
            ><img src="~@image/net-m-close.png"
          /></span>
        </div>
        <div class="filelist">
          <div
            v-for="(item, index) in moveFolder.deskInfoTrees"
            :key="index"
            class="netfile"
            @click="selectFolder(item)"
          >
            <div class="file-item">
              <div class="file-item-image">
                <img
                  src="~@image/net-file.png"
                  alt=""
                  v-if="item.type == 1 && item.isDeleted != -1"
                />
                <img
                  src="~@image/net-file-system.png"
                  alt=""
                  v-if="item.type == 1 && item.isDeleted == -1"
                />
              </div>
              <div class="file-item-main">
                <h1 class="tit">{{ item.filename }}</h1>
                <div class="detail">
                  <span class="detail-time">{{ item.updateTime }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-move">
          <span class="btns" @click="moveToHere">移动到此</span>
        </div>
      </div>
    </van-popup>
    <transition name="mulc">
      <div class="mul-op" v-show="showMoreChoice">
        <div
          class="mul-op-item"
          v-if="tabType != filetype.length - 1"
          @click="downloadFileBeach"
        >
          <img src="~@image/net-op-load-blue.png" alt="" />
          <p>下载</p>
        </div>
        <div
          class="mul-op-item"
          v-if="tabType != filetype.length - 1"
          @click="collectFileBeach"
        >
          <img src="~@image/net-op-collect-blue.png" alt="" />
          <p>收藏</p>
        </div>
        <div
          class="mul-op-item"
          v-if="tabType == filetype.length - 1"
          @click="recoveryBeach"
        >
          <img src="~@image/net-op-recycle-blue.png" alt="" />
          <p>恢复</p>
        </div>
        <div
          class="mul-op-item"
          v-if="tabType != filetype.length - 1"
          @click="delFileBeach"
        >
          <img src="~@image/net-op-del-blue.png" alt="" />
          <p>删除</p>
        </div>
        <div
          class="mul-op-item"
          v-if="tabType == filetype.length - 1"
          @click="physicallyDelFileBeach"
        >
          <img src="~@image/net-op-del-blue.png" alt="" />
          <p>彻底删除</p>
        </div>
        <div
          class="mul-op-item"
          v-if="tabType != filetype.length - 1"
          @click="moveFile"
        >
          <img src="~@image/net-op-move-blue.png" alt="" />
          <p>移动</p>
        </div>
      </div>
    </transition>
    
    <div class="newBuild" @click="onNewBuild">
       <img src="~@image/newBuild.png" alt="">
    </div>

    <van-popup class="newBuildPopup" v-model="NewBuildshow" position="bottom" :style="{ height: '67%' }">
        <van-nav-bar title="新建文件夹" left-text="返回" right-text="完成" left-arrow @click-left="newBuildLeft" @click-right="newBuildRight"/>
        <div class="newBuild-content">
              <img src="~@image/net-file.png" alt="">
              <div class="form">
                  名称：<input type="text" @focus="newBuildValue = ''" v-model="newBuildValue" @blur="onblur(newBuildValue)">
              </div>
        </div>
  
   </van-popup>
  </div>
</template>

<script>
// 导航栏组件
import NavigationBar from "@components/common/NavigationBar.vue";
import NetFileItem from "@components/common/NetFileItem.vue";
import {
  getTopFolder,
  getFileOfType,
  getRecycleBin,
  getFolderChildren,
  renamesFile,
  deleteFile,
  deleteFileBeach,
  physicallyDeleteFile,
  physicallyDeleteFileBeach,
  recoveryFile,
  recoveryFileBeach,
  getCanMoveFolder,
  moveFileToHere,
  batchCollect,
  createNewFolder
} from "@api/network.js";
import qs from "qs";
export default {
  name: "WNetwork",
  data() {
    return {
      //是否刷新页面
      isRefresh: false,
      //是否加载
      loading: false,
      //是否加载完成
      finished: false,
      //页面请求不成功
      torefresh: false,
      //没有文件
      unfile: false,
      //分页码
      pageNum: 1,
      //子级分页码
      childrenPageNum:1,
      //子级总条数
      childrenPages:'',
      //分页数
      pageSize: 10,
      searchtip: "搜索网盘文件",
      filetype: [
        {
          name: "全部文件",
        },
        {
          name: "WORD文档",
          type: "1,5",
        },
        {
          name: "EXCEL文本",
          type: "3,7",
        },
        {
          name: "PPT文档",
          type: "0,6",
        },
        {
          name: "PDF文档",
          type: "4",
        },
        {
          name: "回收站",
        },
      ],
      tabType: 0,
      //顶层目录数据
      topFolder: [],
      //移动目录数据
      moveFolder: [],
      //重命名内容
      rename: "",
      //操作确认提示
      showNotice: false,
      //操作面板显示
      showOperate: false,
      //移动面板
      showMoving: false,
      //显示更多操作
      showMoreChoice: false,
      //显示顶部全选栏
      showTopCheckBar: false,
      //显示顶部文件返回
      showTopFileBack: false,
      //是否全选
      isAll: false,
      //某项文件操作
      setting: "",
      //操作的文件数据
      opres: [],
      //打开文件夹历史记录(移动目录)
      disMove: [],
      //打开top文件目录记录
      disTop: [],
      //总页数
      pages: 0,
      //是否长按
      isLongPress: false,
      //新建弹窗
      NewBuildshow:false,
      newBuildValue:"新建文件夹",
      parentDom:'',  //父dom
      parentId:'',             //顶级父id
      whetherChildren:false,    //判断onload执行
      allparentDom:[],          //所有的父级数组
      tabShow:true,            //控制tab显示隐藏
      scrollNum:0,
    };
  },
  components: {
    NavigationBar,
    NetFileItem,
  },
  computed: {},
  mounted(){
     window.addEventListener('scroll',this.handleScroll)
  },
  
  destroyed(){
     window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
     handleScroll(){
      let scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
      console.log(scrollTop);
      let scroll = scrollTop - this.scrollNum
      this.scrollNum = scrollTop
      if(scroll<0){
        this.$refs.topInput.slideBlur()
      }else if(scroll>0){
        this.$refs.topInput.slideBlur()
      }else{

      }
    },

    //返回上一级
    onTopFileBack() {
      window.pageYOffset= 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      if(this.allparentDom.length == 1){
        this.tabShow = true
      }

      this.isAll = false;
      this.unfile = false;
      this.torefresh = false;
      this.showMoreChoice = false;
      this.showTopCheckBar = false;
      this.isLongPress = false;

      let toOrigin = true;
      if( this.showMoreChoice == true && this.showTopCheckBar == true){
            this.showMoreChoice = false;
            this.showTopCheckBar = false;
            this.isLongPress = false;
            this.isAll = false;
            this.opres = [];
            this.$refs["netfile"].forEach((item) => {
              item.isMulc = false;
              item.checkMulc = false;
            });
            
          toOrigin = true;
          return toOrigin;
      }
      
      if(this.$refs && this.$refs["netfile"] && this.$refs["netfile"].length){
        this.$refs["netfile"].forEach((item) => (item.isMulc ? item.isMulc = false : null));
      }
      if(this.disTop.length <= 1){
        toOrigin = false;
        return toOrigin;
      }
      this.disTop.splice(this.disTop.length - 1, 1);
      let last = this.disTop.length - 1;
      this.topFolder = this.disTop[last];
      this.allparentDom.splice(this.allparentDom.length - 1,1)
      this.parentDom = this.allparentDom[this.allparentDom.length - 1]
      if (this.disTop.length <= 1 && this.allparentDom.length <= 1) {
        this.showTopFileBack = false;
      }
      return toOrigin;
    },
    //下拉刷新
    onRefresh() {
      this.pageNum = 1;
      this.torefresh = false;
      this.isRefresh = true;
       this.topFolder = [];
       this.opres = [];
       this.disTop = [];
       this.allparentDom = [];
      this.onTabChange();
    },
    //tab分类
    onTabChange(title) {
      this.pageNum = 1;
      this.showTopCheckBar = false;
      this.showTopFileBack = false;
      this.showMoreChoice = false;
      this.isLongPress = false;
      this.$refs.topInput.slideBlur()
      setTimeout(() => {
       this.topFolder = [];
       this.opres = [];
       this.disTop = [];
       this.allparentDom = [];
        this.getData(title);
      }, 300);
    },
    //加载列表内容
    onLoad() {
      if(this.whetherChildren == true){
        if (this.childrenPageNum < this.childrenPages) {
          this.childrenPageNum++;
          this.getchildren();
        }
      }else{
        if (this.pageNum < this.pages) {
          this.pageNum++;
          this.getData(this.tabType);
        }
      }
    },
    
    allChoice(){
      this.showMoreChoice = !this.showMoreChoice ;
      //  this.showTopCheckBar = true;
      this.isLongPress =  !this.isLongPress
      this.isAll = !this.isAll;
      this.opres = [];
      this.topFolder.forEach((item) => {
        this.$refs["netfile"].forEach((ref) => {
          if (ref.file.id == item.id) {
            if (!this.isAll) {
              ref.checkMulc = false;
              ref.isMulc = false
            } else {
              ref.checkMulc = true;
              ref.isMulc = true;
              this.opres.push(item);
            }
          }
        });
      });
    },
    
    async getchildren(){
      window.pageYOffset= 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0

        this.$toast.allowMultiple();
        this.$toast.loading({
          forbidClick: true,
          loadingType: "spinner",
        });
       const info = {
          id:this.parentDom.id,
          pageNo:this.childrenPageNum,
          pageSize:this.pageSize,
       }
       const infoData = await getFolderChildren(qs.stringify(info))
       if(infoData.code === 0){
              this.$toast.clear()
              infoData.data.children.data.map(item =>{
                 this.topFolder.push(item)
              })
              this.parentDom = infoData.data.node
              this.childrenPages = infoData.data.children.totalPages

              this.isRefresh = false;
              this.loading = false;
              this.finished = false;
              this.unfile = false;
              this.whetherChildren = true
              if (infoData.data.children.data.length == 0) {
                   this.loading = false;
                   this.finished = true;
                   this.unfile = true;
                   return;
              }
          
              if (this.childrenPageNum >= infoData.data.children.totalPages) {
                this.finished = true;
                this.loading = false;
              }
       }
    },

    //搜索文件
    handleSearch(val) {
      this.$router.push({
        path: `/netfilesearch/${val}`,
      });
    },

    //新建文件夹
    onNewBuild(){
       this.NewBuildshow = true
    },
    //取消新建文件夹
    newBuildLeft(){
      this.NewBuildshow = false
    },
    //提交新建文件夹
    async newBuildRight(){
      if(this.parentDom =="" || this.parentDom == null || this.parentDom == undefined){
          const data = {
           name:this.newBuildValue,
           parentId:this.parentId,
         }
         const res = await createNewFolder(qs.stringify(data))
         if(res.code === 0 ){
           this.NewBuildshow = false
           this.topFolder = []
           this.getData(this.tabType)
           this.newBuildValue = "新建文件夹"
           setTimeout(() => {
             this.$toast(res.msg)
           }, 500);
         }else{
           this.$toast("文件或文件夹已存在，请重新命名")
         }
      }else{
         const data = {
           name:this.newBuildValue,
           parentId:this.parentDom.id,
         }
         const res = await createNewFolder(qs.stringify(data))
         if(res.code === 0 ){
           this.NewBuildshow = false
           this.topFolder = []
           this.getchildren()
           this.newBuildValue = "新建文件夹"
           setTimeout(() => {
             this.$toast(res.msg)
           }, 500);
         }else{
           this.$toast("文件或文件夹已存在，请重新命名")
         }
      }
    },
    //获取焦点
    onblur(value){
       if(this.newBuildValue=="" || this.newBuildValue == undefined || this.newBuildValue == null){
         this.newBuildValue = "新建文件夹"
       }else{
          this.newBuildValue = value
       }
    },

    //打开文件夹或文件夹
    openFolder(file) {
      // this.$toast.loading({
      //   forbidClick: true,
      //   loadingType: "spinner",
      // });
      window.pageYOffset= 0
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      
      this.tabShow = false
      if (!this.showMoreChoice) {
        if (file.type === 1) {
           this.$toast.allowMultiple();
            this.$toast.loading({
              forbidClick: true,
              loadingType: "spinner",
            });
              let params = {
            id: file.id,
            pageNo: this.childrenPageNum,
            pageSize: this.pageSize,
          };
          getFolderChildren(qs.stringify(params))
            .then((res) => {
              this.$toast.clear()
              this.topFolder = [];
              this.showTopFileBack = true;
              if (res.code == 0) {
                if (res.data.children.data.length == 0) {
                  //  this.loading = false;
                   this.finished = true;
                   this.unfile = true;
                  //  this.parentDom = res.data.node;
                  //  this.allparentDom.push(res.data.node)
                  //  return;
                }else{
                   this.finished = false;
                   this.unfile = false;
                }
                this.whetherChildren = true
                this.isRefresh = false;
                this.loading = false;
               
                if (this.childrenPageNum >= res.data.children.totalPages) {
                  this.finished = true;
                  this.loading = false;
                }
                res.data.children.data.map(item =>{
                  this.topFolder.push(item)
                })
                this.allparentDom.push(res.data.node)
                this.parentDom = res.data.node
                this.disTop.push(this.topFolder)
                this.childrenPages = res.data.children.totalPages
              }
            })
            .catch(() => {
              this.$toast("网网出去耍了，等会才回来~");
            });
        } else if (file.type === 2) {
          this.$router.push({
            name: "fileword",
            query: {
              id: file.id,
              tabType:this.tabType
            },
          });
        }
      } else {
        this.topFolder.forEach((item) => {
          if (item.id == file.id) {
            this.$refs["netfile"].forEach((ref) => {
              if (ref.file.id == item.id) {
                ref.checkMulc = !ref.checkMulc;
                if (ref.checkMulc) {
                  this.opres.push(item);
                } else {
                  this.opres.forEach((sel, inx) => {
                    if (sel.id == item.id) {
                      this.opres.splice(inx, 1);
                    }
                  });
                }
              }
            });
          }
        });
        this.isAll = this.$refs["netfile"].every((item) => item.checkMulc);
      }
    },
    //打开文件操作面板
    opFile(file) {
      this.showOperate = true;
      this.opres = [];
      this.opres.push(file);
    },
    //多选操作
    mulchoice(file) {
      this.showMoreChoice = true;
      this.showTopCheckBar = true;
      this.isLongPress = true;
      this.opres = [];
      this.opres.push(file);
      this.$refs["netfile"].forEach((item) => (item.isMulc ? null : true));
      this.isAll = this.$refs["netfile"].every((item) => item.checkMulc);
    },
    //取消多选操作
    onCheckCancel() {
      this.showMoreChoice = false;
      this.showTopCheckBar = false;
      this.isLongPress = false;
      this.isAll = false;
      this.opres = [];
      this.$refs["netfile"].forEach((item) => {
        item.isMulc = false;
        item.checkMulc = false;
      });
    },
    //全选文件/取消全选
    onAllChecks() {
      this.isAll = !this.isAll;
      this.opres = [];
      this.topFolder.forEach((item) => {
        this.$refs["netfile"].forEach((ref) => {
          if (ref.file.id == item.id) {
            if (!this.isAll) {
              ref.checkMulc = false;
            } else {
              ref.checkMulc = true;
              this.opres.push(item);
            }
          }
        });
      });
    },
    //展示可移动文件
    moveFile() {
      for(var i = 0;i<this.opres.length;i++){
         if(this.opres[i].isDeleted == -1){
           this.$toast("系统文件夹不可移动！")
           return
         }
      }
      this.showOperate = false;
      this.showMoving = true;
      getCanMoveFolder()
        .then((res) => {
          if (res.code == 0) {
            this.moveFolder = res.data;
            this.disMove.push(this.moveFolder);
          }
        })
        .catch((err) => {
          this.$toast({
            message: "打开失败",
            position: "bottom",
          });
        });
    },
    //关闭移动
    hideMoveFile() {
      this.showMoving = false;
      this.disMove = [];
    },
    //选中进入文件夹
    selectFolder(file) {
      if (!file.deskInfoTrees.length) {
        this.moveFolder = {};
      }
      this.moveFolder = file;
      this.disMove.push(file);
    },
    //移动到此文件夹
    moveToHere() {
      this.opres.forEach((item, index) => {
        if(item.id == this.moveFolder.id){
          this.$toast("不能移动到相同文件夹下")
           return
        }
        if(item.filetype == -1){
          this.$toast("文件夹不能移动！")
          return
        }
        let params = {
          sourceId: item.id,
          targetId: this.moveFolder.id,
        };
        moveFileToHere(qs.stringify(params)).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
          } else {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
          }
          if (index == this.opres.length - 1) {
            this.hideMoveFile();
            this.onCheckCancel();
          }
        });
        this.topFolder.forEach((top, tindex) => {
          if (item.id === top.id) {
            this.topFolder.splice(tindex, 1);
          }
        });
      });
    },
    //返回上级移动目录
    backPrevFolder() {
      this.disMove.splice(this.disMove.length - 1, 1);
      let last = this.disMove.length - 1;
      this.moveFolder = this.disMove[last];
    },
    //重命名文件
    renameFile() {

      this.rename = this.opres[0].filename.substring(
        0,
        this.opres[0].filename.lastIndexOf(".")
      );
      if (this.opres[0].filename.lastIndexOf(".") === -1)
        this.rename = this.opres[0].filename;
      this.showOperate = false;
      this.showNotice = true;
      this.setting = "重命名";
    },
    //删除文件
    delFile() {
      this.showOperate = false;
      this.showNotice = true;
      this.setting = "删除";
    },
    //彻底删除文件
    physicallyDelFile() {
      this.showOperate = false;
      this.showNotice = true;
      this.setting = "彻底删除";
    },
    //批量删除文件
    delFileBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请先选择文件",
          position: "bottom",
        });
        return;
      }
      this.showNotice = true;
      this.setting = "批量删除";
    },
    //批量彻底删除文件
    physicallyDelFileBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请先选择文件",
          position: "bottom",
        });
        return;
      }
      this.showNotice = true;
      this.setting = "批量彻底删除";
    },
    //恢复文件
    recovery() {
      this.showOperate = false;
      this.showNotice = true;
      this.setting = "恢复";
    },
    //批量恢复
    recoveryBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请先选择文件",
          position: "bottom",
        });
        return;
      }
      this.showNotice = true;
      this.setting = "批量恢复";
    },
    //分享文件
    shareFile() {
      this.showOperate = false;
      this.showNotice = true;
      this.setting = "分享";
    },
    //多选下载文件
    downloadFileBeach() {
      if (!this.opres.length) {
        this.$toast({
          message: "请先选择文件",
          position: "bottom",
        });
        return;
      }

      for(var i = 0;i<this.opres.length;i++){
        if(this.opres[i].filetype == -1){
          this.$toast("文件夹不能下载")
          return
        }
      }

      this.showNotice = true;
      this.setting = "批量下载";
    },
    //批量收藏
    collectFileBeach() {
      for(var i = 0;i<this.opres.length;i++){
        if(this.opres[i].filetype == -1 ){
           this.$toast("文件夹不可以收藏！")
           return
        }
      }
      if (!this.opres.length) {
        this.$toast({
          message: "请先选择文件",
          position: "bottom",
        });
        return;
      }
      let params = [];
      this.opres.forEach((i) => {
        params.push({
          id: i.id,
          status: 1,
          type: i.type,
        });
      });
      batchCollect(params).then((res) => {
        if (res.code == 0) {
          this.$toast("收藏成功");
          this.onCheckCancel();
        }
      });
    },
    //取消文件操作
    cancel() {
      this.showNotice = false;
    },
    //确定操作
    sure() {
      this.showNotice = false;
      let params = {
        id: this.opres[0].id,
      };
      if (this.setting == "重命名") {
        if (!this.rename) {
          this.$toast("请输入新文件名");
          return;
        }
        let suffix =
          this.opres[0].filename.substring(
            this.opres[0].filename.lastIndexOf(".")
          ) === ""
            ? ""
            : this.opres[0].filename.substring(
                this.opres[0].filename.lastIndexOf(".")
              );
        params["name"] = this.rename + suffix;
        renamesFile(qs.stringify(params)).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
           if(this.parentDom.length != 0){
              this.topFolder = []
              this.getchildren();
            }else{
              this.onTabChange()
            }
          }
        });
      } else if (this.setting == "分享") {
        if (window.android) {
          const jsonStr = {
            action: "share",
            data: {
              type: 2,
              ids: this.opres[0].id,
              filename: this.opres[0].filename,
            },
          };
          window.android.postMessage(JSON.stringify(jsonStr));
        }else if(window.webkit){
          const jsonStr = {
            action: "share",
            data: {
              type: 2,
              ids: this.opres[0].id,
              filename: this.opres[0].filename,
            },
          };
          window.webkit.messageHandlers.sendMessageForVue.postMessage(JSON.stringify(jsonStr));
        }
        
      } else if (this.setting == "批量下载") {
        let jsonArr = [];
        this.opres.forEach((i) => {
          jsonArr.push({
            id: i.id,
            filename: i.filename,
          });
        });
        if (window.android) {
          const jsonStr = {
            action: "download",
            data: jsonArr,
          };
          window.android.postMessage(JSON.stringify(jsonStr));
        }
        this.onCheckCancel();
      } else if (this.setting == "删除") {
        if(this.opres[0].isDeleted == -1){
          this.onTabChange(this.tabType);
          this.$toast("该文件夹不能删除")
       }else{
        deleteFile(qs.stringify(params)).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
            this.onTabChange(this.tabType);
          }
        });
       }
      } else if (this.setting == "彻底删除") {
        if(this.opres[0].isDeleted == -1){
          this.onTabChange(this.tabType);
          this.$toast("该文件夹不能删除")
       }else{
        physicallyDeleteFile(qs.stringify(params)).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
            this.onTabChange(this.tabType);
          }
        });
       }
      } else if (this.setting == "批量删除") {
        this.opres.map(item=>{
            if(item.isDeleted == -1){
               this.onTabChange(this.tabType);
               this.$toast("系统文件夹不能删除")
               return
            }
        })

        let ids = []; //id集合
        this.opres.forEach((i) => ids.push(i.id));
        ids = ids.join(",");
        deleteFileBeach(qs.stringify({ ids })).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
            this.onTabChange(this.tabType);
          } else {
            // this.$toast(res.msg);
          }
        });
      } else if (this.setting == "批量彻底删除") {
        if(this.opres[0].isDeleted == -1){
          this.onTabChange(this.tabType);
          this.$toast("该文件夹不能删除")
       }else{
        let ids = []; //id集合
        this.opres.forEach((i) => ids.push(i.id));
        ids = ids.join(",");
        physicallyDeleteFileBeach(qs.stringify({ ids })).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
            this.onTabChange(this.tabType);
          }
        });
       }
      } else if (this.setting == "恢复") {
        recoveryFile(qs.stringify(params)).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
            this.onTabChange(this.tabType);
          }
        });
      } else if (this.setting == "批量恢复") {
        let ids = []; //id集合
        this.opres.forEach((i) => ids.push(i.id));
        ids = ids.join(",");
        recoveryFileBeach(qs.stringify({ ids })).then((res) => {
          if (res.code == 0) {
            this.$toast({
              message: res.msg,
              position: "bottom",
            });
            this.onTabChange(this.tabType);
          }
        });
      }
    },
    //获取顶层目录
    async getData(title) {
      this.whetherChildren = false
      this.$toast.allowMultiple();
      this.$toast.loading({
        forbidClick: true,
        loadingType: "spinner",
      });
      this.disTop = [];
      if (title === 0) {
        this.pageNum = 1;
      }
      if (this.tabType === 0) {
        let params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        };
        const result = await getTopFolder(qs.stringify(params));
        if (result.code === 0) {
          this.$toast.clear();
          this.isRefresh = false;
          this.loading = false;
          this.finished = false;
          this.unfile = false;
          this.pages = result.data.totalPages;

          if (result.data.data.length === 0) {
            this.loading = false;
            this.finished = true;
            this.unfile = true;
            return;
          }
          if (this.pageNum >= result.data.totalPages) {
            this.finished = true;
            this.loading = false;
          }
          result.data.data.map(item=>{
            this.topFolder.push(item)
          })
          this.parentId = result.msg
          this.disTop.push(this.topFolder);
        } else {
        }
        if (!result) {
          this.isRefresh = false;
          this.torefresh = true;
        }
      }

      if (this.tabType === this.filetype.length - 1) {
        let params = {
          pageNo: this.pageNum,
          pageSize: this.pageSize,
        };
        getRecycleBin(qs.stringify(params))
          .then((res) => {
            this.$toast.clear();
            this.isRefresh = false;
            this.loading = false;
            this.pages = res.data.totalPages;
            if (res.code == 0) {
              this.unfile = false;
              if (res.data.data.length == 0) {
                this.unfile = true;
              }
              if (res.data.data.length == 0) {
                this.loading = false;
                this.finished = true;
                this.unfile = true;
                return;
              }
              if (this.pageNum >= res.data.totalPages) {
                this.finished = true;
              }
              res.data.data.map(item => {
                this.topFolder.push(item)
              })
              // this.topFolder = this.topFolder.concat(res.data.data);
              this.disTop.push(this.topFolder);
            }
          })
          .catch(() => {
            this.isRefresh = false;
          });
      }

      //其他选项
      if (this.filetype[this.tabType].type) {
        let params = {
          fileType: this.filetype[this.tabType].type,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
        };
        getFileOfType(qs.stringify(params))
          .then((res) => {
            this.$toast.clear();
            this.isRefresh = false;
            this.loading = false;
            this.finished = false;
            this.pages = res.data.totalPages;
            if (res.code == 0) {
              this.unfile = false;
              if (res.data.length == 0) {
                this.unfile = true;
              }

              if (res.data.data.length == 0) {
                this.loading = false;
                this.finished = true;
                this.unfile = true;
                return;
              }
              if (this.pageNum >= this.pages) {
                this.finished = true;
              }
              res.data.data.map(item =>{
                this.topFolder.push(item)
              })
              // this.topFolder = this.topFolder.concat(res.data.data);
              this.disTop.push(this.topFolder);
            }
          })
          .catch(() => {
            this.isRefresh = false;
            this.torefresh = false;
          });
      }
    },
  },
  updated() {
    if (this.isLongPress) {
      this.$refs["netfile"].forEach((ref) => {
        ref.isMulc = true;
      });
    }
  },
  created() {
    if(this.$route.query.tabType){
      this.tabType = this.$route.query.tabType
    }
    if(this.tabType){
       this.onTabChange(this.tabType)
    }else{
       this.getData(this.tabType);
    }
    
    //暴露返回上级目录方法给Android调用
    window.onTopFileBack = this.onTopFileBack;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs__line {
  width: 9.5px;
  height: 2.5px;
  background-color: #3783f3;
}
::v-deep .van-tab {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}
::v-deep .van-tab.van-tab--active {
  color: #333;
}
::v-deep .van-nav-bar.van-hairline--bottom {
  height: 40px;
}
::v-deep .van-nav-bar__title {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}
.network {
  height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-bottom: 49px;
  font-family: PingFang SC;
  .nav-wrap{
    height:40px;
    .topfix{
      position:fixed;
      width:100%;
      top:0;
      z-index:100;
      background-color:#fff;
    }
    ::v-deep .leftimg{
      margin-right: 15px;
    }
  }
  .filechecktop-active {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
  }
  .netfile-tbar-active {
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
  }
  &-content {
    margin-top: 15px;
  }
  &-content-active{
    margin-top: -20px;
  }
  .network-panel {
    .torefresh,
    .unfilehere {
      height: calc(100vh - 100px);
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      line-height: 300px;
      color: #999999;
    }
  }
  //弹框
  .over {
    width: 285px;
    height: 129px;
    border-radius: 10px;

    .content {
      height: 95px;
      border-bottom: 1px solid #eee;
      .tit {
        height: 30px;
        padding-left: 20px;
        background-color: #eee;
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
        color: #999;
      }
      .main {
        height: 65px;
        display: flex;
        justify-content: center;
        align-items: center;
        input {
          width: 220px;
          height: 30px;
          padding-left: 5px;
          color: #666;
          border: 1px solid #aaa;
          border-radius: 5px;
        }
      }
      p {
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #999;
      }
    }

    .btns {
      display: flex;
      align-items: center;
      height: 34px;

      span {
        font-size: 15px;
        font-weight: 500;
        color: #999;
        flex: 1;
        text-align: center;
      }

      .sure {
        color: #4588ff;
      }
    }
  }
  .over-del {
    height: 185px;
    .content {
      padding-top: 30px;
      border: none;
      .tit {
        padding-left: 0;
        background-color: #fff;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
      .main {
        height: 30px;
        margin-top: 10px;
      }
    }
    .btns {
      height: 70px;
      margin-top: 20px;
    }
  }
  .oplist {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 20px;
    .oplist-item {
      display: flex;
      align-items: center;
      height: 40px;
      margin-bottom: 8px;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 12px;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
      }
    }
    .oplist-item:last-child {
      margin-bottom: 0;
    }
  }
  .move {
    padding-bottom: 17px;
    .top {
      position: relative;
      padding: 15px;
      text-align: center;
      font-size: 17px;
      font-weight: bold;
      color: #333333;
      .curfile {
        position: absolute;
        left: 15px;
        width: 100px;
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        i {
          vertical-align: middle;
          margin-bottom: 3px;
        }
      }
      .move-close {
        position: absolute;
        right: 15px;
        margin-top: 5px;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
    .filelist {
      height: 300px;
      padding-bottom: 20px;
      overflow-y: scroll;
      .netfile-selected {
        background-color: rgba(198, 198, 201, 0.2);
      }
      .netfile {
        padding: 10px 15px;
        overflow-y: scroll;
        .file-item {
          display: flex;
          flex-wrap: nowrap;
          // margin-top: 15px;
          &-image {
            img {
              width: 52.5px;
              height: 52.5px;
            }
          }
          &-main {
            position: relative;
            flex: 1;
            margin-left: 15px;
            padding-top: 3.5px;
            .tit {
              width: 230px;
              font-size: 15px;
              font-family: PingFang SC;
              font-weight: bold;
              color: #333333;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
            .detail {
              margin-top: 8px;
              font-size: 14px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #999999;
              &-count {
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
    .btn-move {
      display: flex;
      flex-direction: row-reverse;
      .btns {
        display: inline-block;
        width: 96.5px;
        margin-right: 20px;
        text-align: center;
        line-height: 38px;
        font-size: 13px;
        font-weight: bold;
        color: #ffffff;
        background-color: #3683f2;
        border-radius: 19px;
      }
    }
  }
  .mul-op {
    position: fixed;
    z-index: 100;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 67.5px;
    padding: 10px 0;
    background: #f4f8ff;
    border-radius: 10px 10px 2px 2px;
    &-item {
      text-align: center;
      img {
        width: 19px;
        height: 19px;
      }
      p {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: #000000;
      }
    }
  }
  .mulc-enter,
  .mulc-leave-to {
    transform: translateY(100%);
  }
  .mulc-enter-active,
  .mulc-leave-active {
    transition: all 0.3s;
  }
  .mulc-enter-to,
  .mulc-leave {
    transform: translateY(0);
  }
  .newBuild{
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background: #3783f3;
    position: fixed;
    right: 13px;
    bottom: 95px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 25px;
      height: 25px;
    }
  }
  .newBuildPopup{
     ::v-deep .van-icon{
         color: #333333 ;
     }
     ::v-deep .van-nav-bar__text{
       color: #333333;
     }
     ::v-deep .van-nav-bar__right{
       .van-nav-bar__text{
         color: #1989fa !important;
       }
     }
     .newBuild-content{
       text-align: center;
       margin-top: 66px;
       .form{
         text-align: left;
         margin: 50px 30px 0;
         font-size: 16px;
         font-family: PingFang SC;
         font-weight: 500;
         color: #333333;
         border-bottom: 1px solid rgba(51,51,51,0.2);
         padding-bottom: 20px;
         input{
            width: 258px;
            color:  #999999;
            padding-left: 20px;
         }
       }
     }
  }
}
::v-deep .van-tabs--line .van-tabs__wrap{
    position: fixed;
    background: #fff;
    z-index: 99;
    top: 35px;
}
::v-deep .van-tabs__content{
  margin-top: 20px;
}
::v-deep .van-tabs__content--animated{
    padding-top: 20px;
}
::v-deep .van-icon{
      font-size: 15px;
      color: #999999;
}
::v-deep .van-nav-bar__text{
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #666666;
}
</style>
