<template>
  <div
    class="netfile"
    @click="debounce(openFolder,delay)"
    @touchstart="longPress"
    @touchmove="watchmove"
    @touchend="endPress"
  >
    <div class="file-item">
      <div class="file-item-image">
        <img
          src="~@image/net-file.png"
          alt=""
          v-if="file.type == 1 && file.isDeleted != -1"
        />
        <img
          src="~@image/net-file-system.png"
          alt=""
          v-if="file.type == 1 && file.isDeleted == -1"
        />
        <img
          src="~@image/net-doc-x.png"
          alt=""
          v-if="file.filetype == 1 || file.filetype == 5"
        />
        <img src="~@image/net-txt.png" alt="" v-if="file.filetype == 2" />
        <img
          src="~@image/net-excel.png"
          alt=""
          v-if="file.filetype == 3 || file.filetype == 7"
        />
        <img
          src="~@image/net-presentation.png"
          alt=""
          v-if="file.filetype == 0 || file.filetype == 6"
        />
        <img src="~@image/net-pdf.png" alt="" v-if="file.filetype == 4" />
        <img src="~@image/net-img.png" alt="" v-if="file.filetype == 63" />
      </div>
      <div class="file-item-main">
        <h1 class="tit">{{ file.filename }}{{ getFileType }}</h1>
        <div class="detail">
          <!-- <span class="detail-count" v-if="file.type == 1">5项</span> -->
          <span class="detail-time">{{ file.updateTime.replace(file.updateTime.substring(file.updateTime.lastIndexOf(":")),"").replace('-','.').replace('-','.') }}</span>
        </div>
        
        <div class="op-icon"    v-if="file.type == 1 && isMulc == false">
          <img class="op-icon-arrow" src="~@image/net-arrow-r.png" alt="" />
        </div>
        <div class="op-icon" v-if="file.type != 1 && isMulc == false" v-on:click.stop="opFile">
            <img class="op-icon-more" src="~@image/net-op-big.png" alt="" />
        </div>
        <div class="op-icon" v-show="isMulc && !checkMulc" @click="mulchoice">
            <img class="op-icon-mulc" src="~@image/net-op-mulc.png" alt=""  />
        </div>
        <div class="op-icon" v-show="isMulc && checkMulc" @click="mulchoice">
           <img class="op-icon-mulc" src="~@image/net-op-mulc-active.png" alt="" />
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NetFileItem",
  props: {
    file: Object,
  },
  data() {
    return {
      loop: null,
      //多选
      isMulc: false,
      //选框状态
      checkMulc: false,
      //纵坐标
      sy: 0,
      //打开文件定时
      open : {},
      //防抖定时
      delay : 600,
    };
  },
  computed: {
    //获取文件后缀
    getFileType() {
      return this.file.url !== null
        ? this.file.url.substring(this.file.url.lastIndexOf("."))
        : "";
    },
  },
  methods: {
    debounce(fn, delay) {
      if (this.open.timer) {
        // 进入此分支说明：当前正在一个计时周期中，并且再次触发了事件,取消当前计时，重新开始计时
        clearTimeout(this.open.timer);
      }
        // 进入此分支说明：当前没有计时，则开始新的计时
      this.open.timer = setTimeout(fn, delay);
    },
    //打开文件夹
    openFolder() {
      console.log("click");
      this.$emit("openFolder", this.file);
    },
    //操作文件
    opFile() {
      this.$emit("opFile", this.file);
    },
    //长按
    longPress(e) {
      clearTimeout(this.loop);
      this.sy = e.touches[0].clientY;
        if (!this.isMulc) {
          this.loop = setTimeout(() => {
            this.isMulc = true;
            this.checkMulc = true;
            this.$emit("mulchoice", this.file);
          }, 600);
        }
    },
    //松开
    endPress() {
      clearTimeout(this.loop);
    },
    //移动
    watchmove(e) {
      let ey = e.touches[0].clientY;
      if (this.sy != ey) {
        if (!this.isMulc) {
          clearTimeout(this.loop);
        }
      }
    },
    //改变选框状态
    mulchoice() {
      console.log("mulc");
    },
  },
  watch : {
    isMulc(){
      if(this.isMulc){
        this.delay = 0;
      }else{
        this.delay = 600;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.netfile {
  padding: 10px 15px 0;
  overflow-y: scroll;
  .file-item {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 15px;
    &-image {
      img {
        width: 52.5px;
        height: 52.5px;
      }
    }
    &-main {
      position: relative;
      flex: 1;
      margin-left: 15px;
      padding-top: 3.5px;
      .tit {
        width: 230px;
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .detail {
        margin-top: 8px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #999999;
        &-count {
          margin-right: 15px;
        }
      }
      .op-icon {
        position: absolute;
        right: 0;
        top: calc(50% - 12.5px);
        width: 30px;
        height: 30px;
        text-align: center;
        &-arrow {
          width: 9px;
          height: 16px;
          margin: 6px 3px 0 0;
        }
        &-more {
          width: 3px;
          height: 15px;
          margin-right: 3px;
        }
        &-mulc {
          width: 14px;
          height: 14.5px;
          margin: 6px 3px 0 0;
        }
      }
    }
  }
}
</style>