import request from "@utils/request.js";

//搜索文件
export function searchFile(data) {
  return request({
    url: "/cloud/searchFile",
    method: "post",
    data
  });
}

//获取顶层目录
export function getTopFolder(data) {
  return request({
    url: "/cloud/findTopFolder",
    method: "post",
    data
  });
}

//新建文件夹
export function createNewFolder(data) {
  return request({
    url: "desk/createNewFolder",
    method: "post",
    data
  });
}

//文档分类查询
export function getFileOfType(data) {
  return request({
    url: "/cloud/findFileByType",
    method: "post",
    data
  });
}

//打开文件夹
export function getFolderChildren(data) {
  return request({
    url: "/desk/getFolderChildren",
    method: "post",
    data
  });
}

//打开文件
export function getDocumentDetail(data) {
  return request({
    url: "/cloud/getCloudDocInfo",
    method: "post",
    data
  });
}

//文件预览
export function filePreview(data){
  return request({
    url : '/cloud/filePreview',
    method : 'post',
    data
  })
}

//重命名文件
export function renamesFile(data) {
  return request({
    url: "/desk/renames",
    method: "post",
    data
  });
}

//删除文件
export function deleteFile(data) {
  return request({
    url: "/desk/deleteFile",
    method: "post",
    data
  });
}

//恢复文件
export function recoveryFile(data) {
  return request({
    url: "/desk/recoverFile",
    method: "post",
    data
  });
}

//批量恢复文件
export function recoveryFileBeach(data){
  return request({
    url : "/desk/recoverFileBatch",
    method : "post",
    data
  });
}

//获取回收站文件
export function getRecycleBin(data) {
  return request({
    url: "/desk/recycleBin",
    method: "post",
    data
  });
}

//查找移动目录
export function getCanMoveFolder(data){
  return request({
    url : "/cloud/findCatalogue",
    method : "post",
    data
  });
}

//移动文件
export function moveFileToHere(data) {
  return request({
    url: "/cloud/moveFile",
    method: "post",
    data
  });
}

//批量删除文件
export function deleteFileBeach(data){
  return request({
    url : "/desk/delFileBatch",
    method : "post",
    data
  });
}

//彻底删除文件
export function physicallyDeleteFile(data){
  return request({
    url : "/desk/physicallyDeleteFile",
    method : "post",
    data
  });
}

//批量彻底删除文件
export function physicallyDeleteFileBeach(data){
  return request({
    url : "/desk/physicallyDelFileBatch",
    method : "post",
    data
  });
}

//批量收藏或取消收藏
export function batchCollect(data){
  return request({
    url : "/cloud/batchCollect",
    method : "post",
    data,
    headers : {
      "Content-Type" : "application/json"
    }
  });
}
